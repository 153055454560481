import { Grid, List, ListItem, ListItemText, useMediaQuery,ListItemButton, withStyles, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import SkywatchCard from '../Core/Cards/SkywatchCard';
import { LayoutMain } from '../Layout/LayoutMain';
import { ReactComponent as ActiveIcon } from '../../assets/active-policy.svg';
//import './PolicyManagement.scss';
import { PolicyContextStore, TABS } from '../../context/PolicyContext/PolicyContext';
//import ActivePolicies from './PolicyManagementSections/ActivePolicies';
import useFetchPolicies from '../../hooks/useFetchPolicies';
import PaymentMethods from "../Payment/PaymentMethods";
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { CheckoutStep } from '../Payment/CheckoutStep';
import PaymentContext from '../../context/PaymentContext/PaymentContext';

const CustomListItemText = withStyles((theme) => ({
    primary: {
        color: 'white',
        fontSize: '1.15em'
    },
}))(ListItemText);

const CustomListItem = withStyles((theme) => ({
    selected: {
        '& span': {
            color: theme.palette.primary.light,
            fontFamily: 'Poppins-Bold',
        }
    }
}))(ListItem);

const Account = () => {
    const { managementTab, changeTab } = useContext(PolicyContextStore);
    const { path, url } = useRouteMatch();
    const location = useLocation();
    const isTablet = !useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('mobile'));
    const isMobileQuery = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

    return (
        <LayoutMain>
            { !isMobileQuery ?
            <div className="policy-management-title">
                <Grid style={{display:'flex', flexDirection:'column'}} container justifyContent="left">
                    <Typography style={{height:'37px'}} variant="h1" className="blue">Credit Card Details</Typography>
                    <PaymentContext>
                        <CheckoutStep inFlow={false}/>
                    </PaymentContext>
                </Grid>
            </div>
            :
            <Grid style={{display:'flex', flexDirection:'column'}} container className="margin-top-medium" justifyContent='center'>
                <Typography variant="h1" className="blue">Credit Card Details</Typography>
                    <PaymentContext>
                        <CheckoutStep inFlow={false}/>
                    </PaymentContext>
            </Grid>}
        </LayoutMain>
    );
};

export default Account;
