import ApiService, { HTTPMethod } from "./api.core";
import { buildQueryParamsUrl } from "../services/utils";

class PaymentApi {
    async getClientSecret() {
        return ApiService.fetch("payments/me/client-secret", HTTPMethod.GET);
    }

    async addPaymentMethod(data) {
        return ApiService.fetch("payments/me", HTTPMethod.POST, data);
    }

    async getActivePaymentMethod() {
        return ApiService.fetch("payments/me/active-payment-method", HTTPMethod.GET);
    }

    async preBind(quoteId, paymentType) {
        let res = await ApiService.fetch('checkout/pre-bind', HTTPMethod.POST, {
            quote_id: quoteId,
            payment_type: paymentType,
            current_time: new Date()
        })
        
        return res;
    }

    async changeStageFuturePolicy(quoteId){
        return await ApiService.fetch(
            `checkout/change-stage-future-policy/${quoteId}`,
            HTTPMethod.POST
        )
    }

    async checkChargeStatus(transactionId, quoteId) {
        return await ApiService.fetch(
            `checkout/get-transaction-status/${transactionId}/${quoteId}`,
            HTTPMethod.GET
        )
    }
    
    async goBack(quote_id){
        return ApiService.fetch(`checkout/back/${quote_id}`, HTTPMethod.POST);
    }
}

export default new PaymentApi();
