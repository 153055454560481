import React, { useState, useContext, useEffect } from "react"
import { Grid, Typography } from "@material-ui/core"
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement
} from '@stripe/react-stripe-js';
import 'react-credit-cards/es/styles-compiled.css';
import '../CreditCard.scss'
import FlexView from "react-flexview/lib";
import { SessionContextStore } from "../../../context/SessionContext/SessionContext";
import { isInFlow } from "../../../services/utils";
import { BrowserView, isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { CreditCardLogo } from "../../Core/CreditCardLogo";
import NewFillButton from "../../Core/NewFillButton";
import DataLayerService from "../../../services/DataLayerService";
import { OrangeButton } from "../../Core/Buttons/OrangeButton";
import { FlowBackButton } from "../../Core/Buttons/FlowBackButton";
import { LoadingContextStore } from "../../../context/LoadingContext/LoadingContext";
import { PaymentContextStore } from '../../../context/PaymentContext/PaymentContext';
import { MobileWidget } from "../../PurchaseFlow/PriceWidgets/MobileWidget";
import insuranceApi from "../../../api/insurance.api";
import { AlertContextStore } from "../../../context/AlertContext/AlertContext";

var classNames = require('classnames')
const ccstyle = { base: { '::placeholder': {color: 'rgb(159, 173, 197)' } } }

const CreditCardPayment = ({stripe, elements, onPurchase, editMode, setEditMode}) => {
    const { addPaymentMethod, hasPaymentMethod, active, shouldCharge, setFlag } = useContext(PaymentContextStore);
    const { awaitWithLoading } = useContext(LoadingContextStore);
    const [needToRecharge, setNeedToRecharge] = useState(false)
    const location = useLocation()
    const [issuer, setIssuer] = useState('')
    const [expire, setExpire] = useState('0000')
    const [last4, setLast4] = useState('')
    const [focusedCC, setFocusedCC] = useState('number')
    const [error, setError] = useState('')
    const {showError, showInfo} = useContext(AlertContextStore);
    useEffect(() => {
        if(hasPaymentMethod && active.type === 'Card') {
            const { brand, expiration_date, four_last_digits } = active;
            const year = expiration_date.split('/')[1]
            const month = expiration_date.split('/')[0]
            setEditMode(false)
            updateCreditCardVisual(month, year, four_last_digits, brand);
        }
    }, [hasPaymentMethod, active])


    React.useEffect(() => {
        const checkIfNeedToRecharge = async () =>{
            const response = await awaitWithLoading(insuranceApi.checkIfNeedToRecharge())
            if(response.ok){
                setNeedToRecharge(response.data)
            }
        }
        checkIfNeedToRecharge() 
    }, [])

    const onCreditCardNumberChange = (e) => {
        setIssuer(e.brand)
        if(e.complete) {
            const element = elements.getElement(CardExpiryElement)
            element.focus()
            setFocusedCC('expiry')
        }
    }

    const onExpiryChange = (e) => {
        if(e.complete) {
            const element = elements.getElement(CardCvcElement)
            element.focus()
            setFocusedCC('cvc')
        }
    }
    
    const onSave = async () => {
        if(!stripe || !elements) return;

        setFocusedCC('number')
        const number = elements.getElement(CardNumberElement)
        let res = await stripe.createToken(number)

        if(res.error) {
            setError(res.error.message)
            return false
        }

        else if(res.token) {
            let addMethodRes = await addPaymentMethod({ payment_method_type: "Card", payment_method_token: res.token.id });

            if(addMethodRes.ok) {
                updateCreditCardVisual(res.token.card.exp_month, res.token.card.exp_year, res.token.card.last4, res.token.card.brand)
                DataLayerService.pushToDataLayer({
                    'event': 'successful-payment-saved',
                    'payment-method': 'cc',
                })
                return true
            }
        }
        else {
            setError('Unknown error from credit card service provider')
            return false
        }
    }

    const updateCreditCardVisual = (month, year, last4, brand) => {
        setExpire(`${month < 10 ? '0' : ''}${month}/${year}`)
        setLast4('************' + last4)
        setIssuer(brand)
    }

    const editCreditCard = () => {
        setEditMode(true)
    }

    const onEditCreditCard = async () => {
        let isSaved = await awaitWithLoading(onSave())
        if(isSaved && needToRecharge && !isInFlow(location.pathname)){
            await awaitWithLoading(insuranceApi.rechargeUser())
            showInfo({message: "Your payment has been successfully completed. Your coverage will continue without interruption.", title: "Payment Completed"})
        }
        if(!needToRecharge && !isInFlow(location.pathname) && isSaved){
            showInfo({message: "Your payment method has been successfully updated.", title: "Payment Method Updated"})
        }
        if(isSaved) {
            setEditMode(false)
            onPurchase();
        }
    }

    useEffect(() => {
        if(!shouldCharge) return
        editMode ? onEditCreditCard() : onPurchase()
        setFlag(false)
    },[shouldCharge])

    return <React.Fragment>
            <Grid container>
                    <> 
                        {editMode && <Grid container spacing={2} justifyContent='space-between'>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h4" className="grey margin-bottom-8">Credit card details</Typography>
                                <CardNumberElement onFocus={() => setFocusedCC('number')} onReady={(e) => e.focus()} 
                                    options={{placeholder: 'xxxx-xxxx-xxxx-xxxx',                        
                                    style: ccstyle,
                                    showIcon: true,
                                    classes: {base: 'test'}}}
                                    onChange={onCreditCardNumberChange} />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="h4" className="grey">Expiration date</Typography>
                                <div className="full-width margin-top-8">
                                    <CardExpiryElement onFocus={() => setFocusedCC('expiry')} onChange={onExpiryChange} options={{classes: {base: 'test'}, style: ccstyle}} />
                                </div>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Typography variant="h4" className="grey">CVC</Typography>
                                <div className="cvc full-width margin-top-8">
                                    <CardCvcElement onFocus={() => setFocusedCC('cvc')} options={{placeholder: 'cvc', classes: {base: 'test'}, style: ccstyle}} />
                                </div>
                            </Grid>
                        </Grid>}

                        {!editMode && <React.Fragment>
                        <Grid item xs={12}>
                            <FlexView vAlignContent={'center'}>
                                <CreditCardLogo brand={issuer} />
                                <div className="text-small black margin-left-6">xxxx-xxxx-xxxx-{last4.split("*")[last4.split("*").length -1]}</div>
                                <div onClick={editCreditCard} className="text-small blue clickable underline margin-left-6">Edit card</div>
                            </FlexView>
                            </Grid>
                        </React.Fragment>}
                    </>
                    
                    <div className="margin-top-basic">
                        <BrowserView>
                            {!isInFlow(location.pathname) && editMode && <Grid container>
                                <Grid item xs={6}>
                                    <NewFillButton disabled={!editMode} onClick={onEditCreditCard}>{needToRecharge ? 'Complete Payment' :'Save Changes'}</NewFillButton>
                                </Grid>
                            </Grid>}
                        </BrowserView>
                    </div>

                    {error && <div className="text-body red margin-top-basic">{error}</div>}
                    {isMobile && <div className="margin-bottom-basic">
                    <MobileWidget />
                </div>}

                    {isInFlow(location.pathname) && <Grid item className="margin-top-basic">
                        <FlowBackButton />
                        <OrangeButton form="checkout" type="submit">Complete Purchase</OrangeButton>
                    </Grid>}
            </Grid>
    </React.Fragment>
}

export default CreditCardPayment;